import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/Analytics.vue'),
  },
  {
    path: '/link-builder-partners',
    name: 'Link building partners',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/partner/LinkBuilderPartners.vue'),
  },

  {
    path: '/link-builder-partner/:id',
    name: 'Link builder partner',
    meta: { layout: 'main', isPublic: false, contentType: 'card' },
    component: () => import('../views/partner/LinkBuilderPartner.vue'),
  },

  {
    path: '/users',
    name: 'Users',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/Users.vue'),
  },
  {
    path: '/links-from-partner',
    name: 'Links From Partner',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/partner/LinksPartner.vue'),
  },
  {
    path: '/links-from-do',
    name: 'Links From DO',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/partner/LinksDO.vue'),
  },
  {
    path: '/customers',
    name: 'Customers',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/Customers.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'empty', isPublic: true },
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/ctrls',
    name: 'Controls',
    meta: { layout: 'empty', isPublic: true, contentType: 'list' },
    component: () => import('../views/Controls.vue'),
  },
  {
    path: '/link-statuses',
    name: 'Link statuses',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/LinkStatuses.vue'),
  },

  {
    path: '/site-statuses',
    name: 'Site statuses',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/SiteStatuses.vue'),
  },

  {
    path: '/niches',
    name: 'Niches',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/Niches.vue'),
  },
  {
    path: '/partner-statuses',
    name: 'Partner statuses',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/partner/PartnerStatuses.vue'),
  },

  {
    path: '/roles-and-permissions',
    name: 'Roles & Permissions',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/RolesAndPermissions.vue'),
  },

  {
    path: '/reset-password',
    name: 'Reset password',
    meta: { layout: 'empty', isPublic: true, contentType: 'list' },
    component: () => import('../views/ResetPassword.vue'),
  },

  {
    path: '/set-password',
    name: 'Set password',
    meta: { layout: 'empty', isPublic: true, contentType: 'list' },
    component: () => import('../views/SetPassword.vue'),
  },

  {
    path: '/contact-set-password',
    name: 'Contact set password',
    meta: { layout: 'empty', isPublic: true, contentType: 'list' },
    component: () => import('../views/partner/ContactSetPassword.vue'),
  },

  {
    path: '/sites',
    name: 'Sites',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/Sites.vue'),
  },

  {
    path: '/contacts',
    name: 'Contacts',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/Contacts.vue'),
  },

  {
    path: '/links-reasons',
    name: 'Loss Reason',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/LinkReasons.vue'),
  },
  {
    path: '/my-profile',
    name: 'My Profile',
    meta: { layout: 'main', isPublic: false, contentType: 'card' },
    component: () => import('../views/MyProfile.vue'),
  },
  {
    path: '/change-password',
    name: 'Change password',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/ChangePassword.vue'),
  },

  {
    path: '/partners-history/all',
    name: 'Partners history',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/partner/History.vue'),
  },

  {
    path: '/partners-history/:id',
    name: 'Partner history',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/partner/History.vue'),
  },

  {
    path: '/clients-history/all',
    name: 'Clients history',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/client/History.vue'),
  },
  {
    path: '/clients-history/:id',
    name: 'Client history',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/client/History.vue'),
  },

  {
    path: '/client-statuses',
    name: 'Client statuses',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/client/ClientStatuses.vue'),
  },

  {
    path: '/client/:id',
    name: 'Client',
    meta: { layout: 'main', isPublic: false, contentType: 'card' },
    component: () => import('../views/client/ClientCard.vue'),
  },

  {
    path: '/client-contact-set-password',
    name: 'Client Contact set password',
    meta: { layout: 'empty', isPublic: true, contentType: 'list' },
    component: () => import('../views/client/ContactSetPassword.vue'),
  },

  {
    path: '/clients',
    name: 'Clients',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/client/Clients.vue'),
  },
  {
    path: '/requirements',
    name: 'Requirements',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/Requirements.vue'),
  },
  {
    path: '/slack-community-status',
    name: 'Slack Community Statuses',
    meta: { layout: 'main', isPublic: false, contentType: 'list' },
    component: () => import('../views/partner/SlackCommunityStatuses.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const isPublic = to.meta.isPublic
  const isAuthenticated = JSON.parse(
    localStorage.getItem('userAuth')
  )?.access_token

  if (!isAuthenticated && !isPublic) {
    return next({ path: '/login' })
  }

  const contentType = to.meta.contentType
  let mainContentParams = store.state.mainContentParams

  if (!mainContentParams) mainContentParams = { contentType: contentType }
  else
    mainContentParams = {
      ...mainContentParams,
      ...{ contentType: contentType },
    }

  store.commit('setMainContentParams', mainContentParams)

  next()
})

export default router
