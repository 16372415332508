<template>
  <div class="main-menu">
    <div class="main-menu-logo">
      <router-link
        v-if="isPartnerContact || isClientContact"
        to="/"
        v-html="Logotype"
      ></router-link>

      <router-link
        v-else
        :to="'/link-builder-partners'"
        v-html="Logotype"
      ></router-link>
    </div>
    <template v-if="!isPartnerContact && !isClientContact">
      <div class="main-menu-title">Main menu</div>
      <template v-for="(item, key) in menuItems" :key="key">
        <router-link v-if="item.isVisible" :to="item.to">
          <div class="main-menu-item" :class="{ active: item.isActive }">
            <div class="icon" v-html="item.icon"></div>
            <div class="text">{{ item.text }}</div>
          </div>
        </router-link>
      </template>

      <div class="main-menu-footer" v-if="hasAccess(permissions.show_settings)">
        <div class="main-menu-separator"></div>
        <div class="main-menu-title">SETTINGS</div>
        <template v-for="(item, key) in menuInfoItems" :key="key">
          <router-link v-if="item.isVisible" :to="item.to">
            <div class="main-menu-item">
              <div class="text">{{ item.text }}</div>
            </div>
          </router-link>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import {
  Dashboard,
  LinkBuilderPartners,
  /* Users, RolesAndPermissions,*/ Links,
  // Customers,
  Contacts,
  Logotype,
  Sites,
  Olumpys,
  Clients,
} from '@/utils/icons'
import { hasAccess, permissions } from '@/utils/permissions'

export default {
  name: 'MainMenu',

  data() {
    return {
      permissions,
      Logotype,
      menuItems: [
        {
          to: '/',
          text: 'Dashboard',
          icon: Dashboard,
          isActive: false,
          isVisible: true,
        },
        {
          to: '/link-builder-partners',
          text: 'Link building partners',
          icon: LinkBuilderPartners,
          isActive: false,
          isVisible: hasAccess(permissions.get_partners),
        },
        {
          to: '/links-from-partner',
          text: 'Links from partner',
          icon: Links,
          isActive: false,
          isVisible: hasAccess(permissions.get_links),
        },
        {
          to: '/links-from-do',
          text: 'Links from DO',
          icon: Olumpys,
          isActive: false,
          isVisible: hasAccess(permissions.get_links),
        },
        {
          to: '/sites',
          text: 'Sites',
          icon: Sites,
          isActive: false,
          isVisible: hasAccess(permissions.get_sites),
        },
        {
          to: '/clients',
          text: 'Clients',
          icon: Clients,
          isActive: false,
          isVisible: true,
        },
        {
          to: '/contacts',
          text: 'Contacts',
          icon: Contacts,
          isActive: false,
          isVisible: hasAccess(permissions.get_contacts),
        },
        // {
        //   to: '/customers',
        //   text: 'Customers',
        //   icon: Customers,
        //   isActive: false,
        //   isVisible: false,
        // },
        // {
        //   to: '/contacts',
        //   text: 'Contacts',
        //   icon: Contacts,
        //   isActive: false,
        //   isVisible: true,
        // },
      ],

      menuInfoItems: [
        {
          to: '/users',
          text: 'Users' /*, icon: Users*/,
          isActive: false,
          isVisible: hasAccess(permissions.get_users),
        },
        {
          to: '/roles-and-permissions',
          text: 'Roles & Permissions' /*, icon: RolesAndPermissions*/,
          isActive: false,
          isVisible: !(
            !hasAccess(permissions.get_roles) &&
            !hasAccess(permissions.get_permissions)
          ),
        },
        {
          to: '/niches',
          text: 'Niches',
          isActive: false,
          isVisible: hasAccess(permissions.get_niches),
        },
        {
          to: '/link-statuses',
          text: 'Link Statuses',
          isActive: false,
          isVisible: hasAccess(permissions.get_link_statuses),
        },
        {
          to: '/links-reasons',
          text: 'Loss Reason',
          isActive: false,
          isVisible: hasAccess(permissions.get_link_reasons),
        },

        {
          to: '/partner-statuses',
          text: 'Partner Statuses',
          isActive: false,
          isVisible: hasAccess(permissions.get_partner_statuses),
        },
        {
          to: '/client-statuses',
          text: 'Client Statuses',
          isActive: false,
          isVisible: hasAccess(permissions.get_client_statuses),
        },
        {
          to: '/site-statuses',
          text: 'Site Statuses',
          isActive: false,
          isVisible: hasAccess(permissions.get_site_statuses),
        },
        {
          to: '/partners-history/all',
          text: 'History',
          isActive: false,
          isVisible:
            hasAccess(permissions.get_history) ||
            hasAccess(permissions.get_client_history_total),
        },
        {
          to: '/requirements',
          text: 'Additional requirements',
          isActive: false,
          isVisible: hasAccess(permissions.get_requirements),
        },
        {
          to: '/slack-community-status',
          text: 'Slack Community Statuses',
          isActive: false,
          isVisible: hasAccess(permissions.get_slack_community_statuses),
        },
      ],
    }
  },

  computed: {
    isPartnerContact() {
      return this.$store.state.currentUser?.partner ? true : false
    },

    isClientContact() {
      return this.$store.state.currentUser?.client ? true : false
    },
  },

  methods: {
    hasAccess(permission) {
      return hasAccess(permission)
    },
  },
}
</script>
